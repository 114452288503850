import React from 'react'

// Styles
import {
  footer,
  footerImg,
  footerLink,
  footerContainer,
  stickyContainer,
} from './styles.module.scss'

const Footer: React.FC = () => {
  return (
    <div className={stickyContainer}>
      <div className={`${footer} font-subscriber`}>
        <div className={footerContainer}>
          <a
            className={footerLink}
            target="_blank"
            href="https://www.facebook.com/FICCOBogota/"
          >
            <img
              src="https://ficcofestival.com/img/assets/Facebook.png"
              className={footerImg}
            />
            Facebook
          </a>
          <a
            className={footerLink}
            target="_blank"
            href="https://www.instagram.com/ficcobogota/"
          >
            <img
              src="https://ficcofestival.com/img/assets/Instagram.png"
              className={footerImg}
            />
            Instagram
          </a>
          <a
            className={footerLink}
            target="_blank"
            href="https://twitter.com/ficcobogota"
          >
            <img
              src="https://ficcofestival.com/img/assets/Twitter.png"
              className={footerImg}
            />
            Twitter
          </a>
          <a
            className={footerLink}
            target="_blank"
            href="https://www.youtube.com/c/FestivalIndependienteDeC%C3%B3micColombiano"
          >
            <img
              src="https://ficcofestival.com/img/assets/YouTube.png"
              className={footerImg}
            />
            YouTube
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
